<script setup lang="ts">
import type { Social, SocialMethod } from "@/types";
const props = withDefaults(
	defineProps<{
		socials: Social[];
		entryPoint: string;
		locationTid: string | boolean | null;
		isSmall?: boolean;
		buttonSize?: "xl" | "lg" | "md" | "s" | "xs";
	}>(),
	{ buttonSize: "xl", locationTid: null }
);
const route = useRoute();

const { open, closeAll } = useAppModals();
const { playerSocLogin, isLoading, handleOAuth } = useOAuth({ open, closeAll });
const storePromoOffer = () => {
	const { promoOffer } = route.query;
	if (promoOffer && !Array.isArray(promoOffer)) {
		localStorage.setItem("promoOffer", promoOffer);
	}
};

const socialMethods = computed(() =>
	props?.socials.map((method) => {
		const data = playerSocLogin.value?.credentials?.[method?.name as SocialMethod];

		return {
			data,
			...method
		};
	})
);

const handleAuth = ({ type, cdn, href }: { type: string; cdn: boolean; href?: string }) => {
	storePromoOffer();

	dispatchGAEvent({
		event: "click_button",
		button_name: `oauth_click-${type}`,
		location: "registration_window"
	});

	if (!cdn && href) {
		window.location.href = href;
		return;
	}

	handleOAuth(type);
};
</script>

<template>
	<div v-if="!isLoading" class="row-social" :class="['row-social', { 'no-social': !socials.length, small: isSmall }]">
		<template v-if="socialMethods">
			<div
				v-for="social in socialMethods"
				:key="social.id"
				class="social"
				:data-tid="locationTid && `${locationTid}-${social.name.toLowerCase()}-btn`"
				@click.stop="handleAuth({ type: social.name, cdn: social?.data?.enabled || false, href: social.href })"
			>
				<div v-if="!playerSocLogin?.enabled || social?.data?.enabled" class="link">
					<ASvg :name="`socials/${social.icon}`" />
				</div>
			</div>
			<div
				v-if="playerSocLogin?.enabled && playerSocLogin?.credentials?.apple?.enabled"
				class="social"
				@click="handleAuth({ type: 'apple', cdn: true })"
			>
				<div class="link">
					<ASvg name="socials/apple" />
				</div>
			</div>
		</template>
	</div>
</template>

<style scoped lang="scss">
.row-social {
	$self: &;
	display: flex;
	gap: 12px;
	padding-bottom: 8px;
	color: var(--conakry);
	width: 100%;
	justify-content: center;

	.link {
		display: flex;
		width: 104px;
		padding: 8px 16px;
		justify-content: center;
		align-items: center;
		gap: 2px;
		flex-shrink: 0;
		border-radius: 8px;
		border: 1px solid var(--neutral-700);
		background: var(--neutral-900);
		cursor: pointer;
		transition: 0.3s;

		&:hover {
			background: var(--neutral-900);
			border-color: var(--neutral-900);
		}

		&:deep(svg) {
			width: 32px;
			height: 32px;
		}
	}

	@media (max-width: 410px) {
		.link {
			width: 80px;
		}
	}

	&.no-social {
		padding: 0;
	}
}
</style>
